import React, { useEffect } from "react";

import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

interface WithAuthProps {
  component:
    | React.LazyExoticComponent<React.ComponentType<any>>
    | React.ComponentType<any>;
  defaultComponent?:
    | React.LazyExoticComponent<React.ComponentType<any>>
    | React.ComponentType<any>;
  allowedRoles?: string[];
  navigateTo?: string;
}

const WithAuth: React.FC<WithAuthProps> = ({
  component: Component,
  defaultComponent: DefaultComponent,
  allowedRoles,
  navigateTo,
  ...rest
}) => {
  const { isUserAuthorized } = useAuth({ allowedRoles });
  const isAuth = isUserAuthorized();
  const navigate = useNavigate();

  useEffect(() => {
    navigateTo && isAuth === false && navigate(navigateTo, { replace: true });
  }, [isAuth]);

  if (isAuth) {
    return <Component {...rest} />;
  } else {
    return DefaultComponent ? <DefaultComponent {...rest} /> : null;
  }
};

export default WithAuth;
