import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Menu, MenuProps } from "antd";

import {
  FileTextOutlined,
  FolderOpenOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import {
  AppRoutes,
  RoutePath,
} from "../../../../app/providers/router/config/routeConfig";
import { classNames } from "../../../../lib/classNames/ClassNames";

interface UserProfileProps {
  className?: string;
}

type MenuItem = Required<MenuProps>["items"][number];
const { Sider } = Layout;

const defaultMenuItems: MenuItem[] = [
  getItem("Главная", AppRoutes.MAIN, <HomeOutlined />, RoutePath.main),
];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  path?: string,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    path,
  } as MenuItem;
}

const items: MenuItem[] = [
  ...defaultMenuItems,
  getItem("Книги", "book-directory", <FolderOpenOutlined />, undefined, [
    getItem("Каталог", AppRoutes.BOOKS, <FileTextOutlined />, RoutePath.books),
    getItem(
      "Аудиокниги",
      AppRoutes.AUDIO_BOOKS,
      <FileTextOutlined />,
      RoutePath.audioBooks
    ),
    getItem(
      "Тесты",
      AppRoutes.BOOK_TESTS,
      <FileTextOutlined />,
      RoutePath.bookTests
    ),
    getItem(
      "Подборки",
      AppRoutes.PLAY_LIST,
      <FileTextOutlined />,
      RoutePath.playList
    ),
    getItem(
      "Обложки",
      AppRoutes.COVERS_BOOKS,
      <FileTextOutlined />,
      RoutePath.coversBooks
    ),
  ]),
  getItem("Папки", "directory", <FolderOpenOutlined />, undefined, [
    getItem("Авторы", AppRoutes.AUTHORS, undefined, RoutePath.authors),
    getItem("Категории", AppRoutes.CATEGORIES, undefined, RoutePath.categories),
    getItem("Теги", AppRoutes.TAGS, undefined, RoutePath.tags),
    getItem(
      "Категории медиа",
      AppRoutes.MEDIA_CATEGORY,
      undefined,
      RoutePath.mediaCategory
    ),
    getItem(
      "Типы приложений",
      AppRoutes.APPLICATION_TYPE,
      undefined,
      RoutePath.applicationType
    ),
  ]),
  getItem(
    "Баннеры",
    AppRoutes.BANNERS,
    <FileTextOutlined />,
    RoutePath.banners
  ),
  getItem("Медиа", AppRoutes.MEDIA, <FileTextOutlined />, RoutePath.media),
  getItem("Статьи", AppRoutes.ARTICLE, <FileTextOutlined />, RoutePath.article),
  getItem(
    "Истории",
    AppRoutes.HISTORY,
    <FileTextOutlined />,
    RoutePath.history
  ),
  getItem("FAQ", AppRoutes.FAQ, <FileTextOutlined />, RoutePath.faq),
  getItem(
    "Обратная связь",
    AppRoutes.FEEDBACK,
    <FileTextOutlined />,
    RoutePath.feedback
  ),
];

export const Sidebar = ({ className = "" }: UserProfileProps) => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();

  const getNameByKey: any = (array: any, key: string) => {
    if (array) {
      for (let i = 0; i < array.length; i++) {
        if (array[i]?.key === key) {
          return array[i];
        } else {
          const childrenByKey = getNameByKey(array[i].children, key);
          if (childrenByKey) return childrenByKey;
        }
      }
    }
    return null;
  };

  const onMenuClick = (event: MenuItem) => {
    const url = getNameByKey(items, event?.key);
    if (url?.path) {
      navigate(url.path);
    }
  };

  return (
    <Sider
      className={classNames("", {}, [className])}
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
    >
      <Menu
        theme="dark"
        defaultSelectedKeys={["1"]}
        mode="inline"
        items={items}
        onClick={onMenuClick}
      />
    </Sider>
  );
};
