import { useKeycloak } from "@react-keycloak/web";

interface UseAuthOptions {
  allowedRoles?: string[];
}

export const useAuth = ({ allowedRoles }: UseAuthOptions) => {
  const { keycloak } = useKeycloak();

  const isUserAuthorized = (): boolean => {
    if (keycloak && keycloak.authenticated) {
      if (allowedRoles && allowedRoles.length > 0) {
        return allowedRoles.some((role) =>
          keycloak.hasResourceRole(
            role,
            process.env.REACT_APP_KEYCLOAK_CLIENT_ID as string
          )
        );
      }
      return true;
    }
    return false;
  };

  return { isUserAuthorized };
};
