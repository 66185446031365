import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { keycloak } from "./lib/keycloak/keycloak";
import { client } from "./app/apollo/client_apollo";
import { ApolloProvider } from "@apollo/client";
import { ReactKeycloakProvider } from "@react-keycloak/web";
//import reportWebVitals from './reportWebVitals';
import LoadingSpinner from "./components/ui/loadingPage/LoadingSpinner";
import { BrowserRouter } from "react-router-dom";
import { BookProvider } from "./components/book/model/BookContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ReactKeycloakProvider
    authClient={keycloak}
    autoRefreshToken
    LoadingComponent={<LoadingSpinner />}
  >
    <ApolloProvider client={client}>
      <BrowserRouter basename='/admin'>
        <BookProvider>
          <App />
        </BookProvider>
      </BrowserRouter>
    </ApolloProvider>
  </ReactKeycloakProvider>
);

//reportWebVitals();
