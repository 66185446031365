import Keycloak from 'keycloak-js'

const keycloakConfig = {
  url: process.env.REACT_APP_KEYCLOAK_API_URL as string,
  realm: process.env.REACT_APP_KEYCLOAK_REALM as string,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID as string
}

export const keycloak = new Keycloak(keycloakConfig);

