import React from 'react'
import {Button, Card} from 'antd'
import logoImage from '../../../assets/react.svg'
import backgroundImage from '../../../assets/background.jpg'
import cls from './Auth.module.scss'
import {keycloak} from "../../../lib/keycloak/keycloak";

const AuthPage: React.FC = () => {
  const login = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault()
    keycloak.login()
  }

  return (
    <div className={cls.Auth}
         style={{
           backgroundImage: `url(${backgroundImage})`,
           backgroundRepeat: 'no-repeat',
           backgroundPosition: 'center',
           backgroundSize: 'cover',
         }}
    >
      <Card className={cls.form}>
        <img src={logoImage} height='48px' alt='React Starter'/>
        <h1>НЭБ Свет</h1>
        <p>Панель администрирования</p>

        <Button type="primary" size={"large"} onClick={login}>Авторизоваться</Button>
      </Card>
    </div>
  );
}

export default AuthPage
