import { Layout } from "antd";
import UserProfile from "./components/layout/header/UserProfile/UserProfile";
import { Sidebar } from "./components/layout/sidebar";
import { AppRouter } from "./app/providers/router";
import { useKeycloak } from "@react-keycloak/web";

import "./App.scss";
import AuthPage from "./pages/Auth/ui/AuthPage";
import WithAuth from "./components/auth/withAuth";
import { ALLOWED_ROLES } from "./const/auth";

const { Header, Content, Footer } = Layout;

function App() {
  const { keycloak } = useKeycloak();
  const isLoggedIn = keycloak.authenticated;

  const getCopyright = () => {
    const year = new Date().getFullYear();
    return `РГБ ©${year}`;
  };

  return isLoggedIn ? (
    <Layout className="App-layout">
      <WithAuth
        component={() => <Sidebar className="App-sidebar" />}
        allowedRoles={ALLOWED_ROLES}
      />

      <Layout>
        <Header className="App-header">
          <UserProfile />
        </Header>
        <Content className="App-content">
          <AppRouter />
        </Content>
        <Footer className="App-footer">{getCopyright()}</Footer>
      </Layout>
    </Layout>
  ) : (
    <AuthPage />
  );
}

export default App;
