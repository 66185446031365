import React from 'react';
import {keycloak} from "../../../../lib/keycloak/keycloak";
import {Button, Dropdown, Menu} from "antd";

const UserProfile: React.FC = () => {
  const menu = (
    <Menu items={
      [
        {
          key: 'logout',
          label: (
            <Button type={"text"} onClick={() => logout()}>Выйти</Button>
          ),
        }
      ]}
    />
  );

  const logout = () => {
    keycloak.logout()
  }

  const login = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault()
    keycloak.login()
  }

  return (
    <div style={{marginLeft: "auto", textAlign: 'right', paddingRight: 40}}>
      {keycloak.authenticated
        ? (
          <Dropdown overlay={menu} placement="topRight">
            <a href="/" onClick={e => e.preventDefault()}>{keycloak.tokenParsed?.preferred_username}</a>
          </Dropdown>
        )
        : (
          <a href="?" onClick={login}>Login</a>
        )
      }
    </div>
  );
};

export default UserProfile;
