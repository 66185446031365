import { RouteProps } from "react-router-dom";
import { PlayListPage } from "../../../../pages/neb_nest/play_list";
import { FaqPage } from "../../../../pages/neb_nest/faq";
import { FeedBackPage } from "../../../../pages/neb_nest/feedback";
import { HistoryPage } from "../../../../pages/neb_nest/history";
import { MainPage } from "../../../../pages/MainPage";
import { AudioBookPage } from "../../../../pages/neb_files/audio_book";
import { CategoriesPage } from "../../../../pages/neb_files/category";
import { CoversBookPage } from "../../../../pages/neb_files/covers";
import { TagsPage } from "../../../../pages/neb_files/tag";
import {
  AuthorsPage,
  CreateAuthorPage,
  EditAuthorPage,
} from "../../../../pages/neb_files/author";
import {
  BooksPage,
  CreateBookPage,
  EditBookPage,
} from "../../../../pages/neb_files/books";
import { NotFoundPage } from "../../../../pages/NotFoundPage";
import {
  BookTestPage,
  EditBookTestPage,
} from "../../../../pages/neb_nest/book-tests";
import {
  CreateMediaPage,
  MediaPage,
  UpdateMediaPage,
} from "../../../../pages/neb_nest/media";
import { MediaCategoryPage } from "../../../../pages/neb_nest/media-category";
import {
  ArticleDetailPage,
  ArticlePage,
} from "../../../../pages/neb_nest/article";
import { BannerPage } from "../../../../pages/neb_nest/banner";
import WithAuth from "../../../../components/auth/withAuth";
import { ALLOWED_ROLES } from "../../../../const/auth";
import { Result } from "antd";
import { ApplicationTypePage } from "../../../../pages/neb_files/ApplicationType";

export enum AppRoutes {
  MAIN = "main",
  ARTICLE = "article",
  ARTICLE_DETAIL = "articleDetail",
  BOOKS = "books",
  BOOK_EDIT = "bookEdit",
  BOOK_CREATE = "bookCreate",
  BOOK_TESTS = "bookTests",
  BOOK_TESTS_EDIT = "bookTestsEdit",
  AUTHORS = "authors",
  AUTHOR_EDIT = "authorEdit",
  AUTHOR_CREATE = "authorCreate",
  AUDIO_BOOKS = "audioBooks",
  CATEGORIES = "categories",
  TAGS = "tags",
  COVERS_BOOKS = "coversBooks",
  FAQ = "faq",
  FEEDBACK = "feedback",
  HISTORY = "history",
  PLAY_LIST = "playList",
  MEDIA = "media",
  MEDIA_UPDATE = "mediaUpdate",
  MEDIA_CREATE = "mediaCreate",
  MEDIA_CATEGORY = "mediaCategory",
  BANNERS = "banners",
  APPLICATION_TYPE = "applicationType",
  NOT_FOUND = "notFound",
}

export const RoutePath: Record<AppRoutes, string> = {
  [AppRoutes.MAIN]: "/",
  [AppRoutes.ARTICLE]: "/article",
  [AppRoutes.ARTICLE_DETAIL]: "/article/:id",
  [AppRoutes.BOOKS]: "/books",
  [AppRoutes.BOOK_CREATE]: "/book-create",
  [AppRoutes.BOOK_EDIT]: "/book-edit",
  [AppRoutes.BOOK_TESTS]: "/book-tests",
  [AppRoutes.BOOK_TESTS_EDIT]: "/book-tests-edit",
  [AppRoutes.AUTHORS]: "/authors",
  [AppRoutes.AUTHOR_CREATE]: "/author-create",
  [AppRoutes.AUTHOR_EDIT]: "/author-edit",
  [AppRoutes.AUDIO_BOOKS]: "/audio_book",
  [AppRoutes.CATEGORIES]: "/categories",
  [AppRoutes.TAGS]: "/tags",
  [AppRoutes.COVERS_BOOKS]: "/covers",
  [AppRoutes.FAQ]: "/faq",
  [AppRoutes.FEEDBACK]: "/feedback",
  [AppRoutes.HISTORY]: "/history",
  [AppRoutes.PLAY_LIST]: "/play_list",
  [AppRoutes.MEDIA]: "/media",
  [AppRoutes.MEDIA_CREATE]: "/media-create",
  [AppRoutes.MEDIA_UPDATE]: "/media-update",
  [AppRoutes.MEDIA_CATEGORY]: "/media-category",
  [AppRoutes.BANNERS]: "/banners",
  [AppRoutes.APPLICATION_TYPE]: "/application-type",
  [AppRoutes.NOT_FOUND]: "*",
};

export const routeConfig: Record<AppRoutes, RouteProps> = {
  [AppRoutes.MAIN]: {
    path: RoutePath.main,
    element: (
      <WithAuth
        component={MainPage}
        defaultComponent={() => (
          <Result
            status="403"
            title="Доступ запрещен"
            subTitle="Доступ к запрашиваемой странице запрещен или у пользователя нет прав на просмотр контента"
          />
        )}
        allowedRoles={ALLOWED_ROLES}
      />
    ),
  },
  [AppRoutes.ARTICLE]: {
    path: RoutePath.article,
    element: (
      <WithAuth
        component={ArticlePage}
        allowedRoles={ALLOWED_ROLES}
        navigateTo="/"
      />
    ),
  },
  [AppRoutes.ARTICLE_DETAIL]: {
    path: RoutePath.articleDetail,
    element: (
      <WithAuth
        component={ArticleDetailPage}
        allowedRoles={ALLOWED_ROLES}
        navigateTo="/"
      />
    ),
  },
  [AppRoutes.BOOKS]: {
    path: RoutePath.books,
    element: (
      <WithAuth
        component={BooksPage}
        allowedRoles={ALLOWED_ROLES}
        navigateTo="/"
      />
    ),
  },
  [AppRoutes.BOOK_EDIT]: {
    path: RoutePath.bookEdit,
    element: (
      <WithAuth
        component={EditBookPage}
        allowedRoles={ALLOWED_ROLES}
        navigateTo="/"
      />
    ),
  },
  [AppRoutes.BOOK_CREATE]: {
    path: RoutePath.bookCreate,
    element: (
      <WithAuth
        component={CreateBookPage}
        allowedRoles={ALLOWED_ROLES}
        navigateTo="/"
      />
    ),
  },
  [AppRoutes.BOOK_TESTS]: {
    path: RoutePath.bookTests,
    element: (
      <WithAuth
        component={BookTestPage}
        allowedRoles={ALLOWED_ROLES}
        navigateTo="/"
      />
    ),
  },
  [AppRoutes.BOOK_TESTS_EDIT]: {
    path: RoutePath.bookTestsEdit,
    element: (
      <WithAuth
        component={EditBookTestPage}
        allowedRoles={ALLOWED_ROLES}
        navigateTo="/"
      />
    ),
  },
  [AppRoutes.AUTHORS]: {
    path: RoutePath.authors,
    element: (
      <WithAuth
        component={AuthorsPage}
        allowedRoles={ALLOWED_ROLES}
        navigateTo="/"
      />
    ),
  },
  [AppRoutes.AUTHOR_EDIT]: {
    path: RoutePath.authorEdit,
    element: (
      <WithAuth
        component={EditAuthorPage}
        allowedRoles={ALLOWED_ROLES}
        navigateTo="/"
      />
    ),
  },
  [AppRoutes.AUTHOR_CREATE]: {
    path: RoutePath.authorCreate,
    element: (
      <WithAuth
        component={CreateAuthorPage}
        allowedRoles={ALLOWED_ROLES}
        navigateTo="/"
      />
    ),
  },
  [AppRoutes.AUDIO_BOOKS]: {
    path: RoutePath.audioBooks,
    element: (
      <WithAuth
        component={AudioBookPage}
        allowedRoles={ALLOWED_ROLES}
        navigateTo="/"
      />
    ),
  },
  [AppRoutes.CATEGORIES]: {
    path: RoutePath.categories,
    element: (
      <WithAuth
        component={CategoriesPage}
        allowedRoles={ALLOWED_ROLES}
        navigateTo="/"
      />
    ),
  },
  [AppRoutes.COVERS_BOOKS]: {
    path: RoutePath.coversBooks,
    element: (
      <WithAuth
        component={CoversBookPage}
        allowedRoles={ALLOWED_ROLES}
        navigateTo="/"
      />
    ),
  },
  [AppRoutes.TAGS]: {
    path: RoutePath.tags,
    element: (
      <WithAuth
        component={TagsPage}
        allowedRoles={ALLOWED_ROLES}
        navigateTo="/"
      />
    ),
  },
  [AppRoutes.FAQ]: {
    path: RoutePath.faq,
    element: (
      <WithAuth
        component={FaqPage}
        allowedRoles={ALLOWED_ROLES}
        navigateTo="/"
      />
    ),
  },
  [AppRoutes.FEEDBACK]: {
    path: RoutePath.feedback,
    element: (
      <WithAuth
        component={FeedBackPage}
        allowedRoles={ALLOWED_ROLES}
        navigateTo="/"
      />
    ),
  },
  [AppRoutes.HISTORY]: {
    path: RoutePath.history,
    element: (
      <WithAuth
        component={HistoryPage}
        allowedRoles={ALLOWED_ROLES}
        navigateTo="/"
      />
    ),
  },
  [AppRoutes.PLAY_LIST]: {
    path: RoutePath.playList,
    element: (
      <WithAuth
        component={PlayListPage}
        allowedRoles={ALLOWED_ROLES}
        navigateTo="/"
      />
    ),
  },
  [AppRoutes.MEDIA]: {
    path: RoutePath.media,
    element: (
      <WithAuth
        component={MediaPage}
        allowedRoles={ALLOWED_ROLES}
        navigateTo="/"
      />
    ),
  },
  [AppRoutes.MEDIA_UPDATE]: {
    path: RoutePath.mediaUpdate,
    element: (
      <WithAuth
        component={UpdateMediaPage}
        allowedRoles={ALLOWED_ROLES}
        navigateTo="/"
      />
    ),
  },
  [AppRoutes.MEDIA_CREATE]: {
    path: RoutePath.mediaCreate,
    element: (
      <WithAuth
        component={CreateMediaPage}
        allowedRoles={ALLOWED_ROLES}
        navigateTo="/"
      />
    ),
  },
  [AppRoutes.MEDIA_CATEGORY]: {
    path: RoutePath.mediaCategory,
    element: (
      <WithAuth
        component={MediaCategoryPage}
        allowedRoles={ALLOWED_ROLES}
        navigateTo="/"
      />
    ),
  },
  [AppRoutes.BANNERS]: {
    path: RoutePath.banners,
    element: (
      <WithAuth
        component={BannerPage}
        allowedRoles={ALLOWED_ROLES}
        navigateTo="/"
      />
    ),
  },
  [AppRoutes.APPLICATION_TYPE]: {
    path: RoutePath.applicationType,
    element: (
      <WithAuth
        component={ApplicationTypePage}
        allowedRoles={ALLOWED_ROLES}
        navigateTo="/"
      />
    ),
  },
  [AppRoutes.NOT_FOUND]: {
    path: RoutePath.notFound,
    element: <NotFoundPage />,
  },
};
