import {onError} from "@apollo/client/link/error";
import {ApolloClient, ApolloLink, createHttpLink, InMemoryCache} from "@apollo/client";
import {MultiAPILink} from "@habx/apollo-multi-endpoint-link";
import {keycloak} from "../../lib/keycloak/keycloak";
import {message} from "antd";

const errorLink = onError(({graphQLErrors, networkError, response}) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({message: msg, locations, path, extensions}) => {
        message.error(`${msg}`)
        console.log(
          `[GraphQL error]: Message: ${msg} Path: ${path}`,
        )
      }
    );
  }
  if (networkError) {
    message.error(`[Network error]: ${networkError}`)
    console.log(`[Network error]: ${networkError}`);
  }
});
const link = ApolloLink.from([errorLink,
  new MultiAPILink({
    httpSuffix: '',
    endpoints: {
      neb_files: process.env.REACT_APP_GRAPHQL_API_SERVICE_ONE as string,
      neb_nest: process.env.REACT_APP_GRAPHQL_API_SERVICE_TWO as string,
    },
    createHttpLink: () => createHttpLink(),
    getContext: (endpoint) => ({
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
      },
    })
  }),
])

const cache = new InMemoryCache();

export const client = new ApolloClient({
  link,
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-first",
      errorPolicy: "all",
    },
    query: {
      fetchPolicy: "cache-first",
      errorPolicy: "all",
    },
  },
});
