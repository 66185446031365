import React, { createContext, useContext, useReducer } from "react";
import { GetReferenceCreateBookQuery } from "../../../app/graphql/generated/graphql_neb_files";

interface BookState {
  authorsList: GetReferenceCreateBookQuery["getAllAuthor"];
  tagsList: GetReferenceCreateBookQuery["getAllTag"];
  categoriesList: GetReferenceCreateBookQuery["getAllCategory"];
  applicationTypeList: GetReferenceCreateBookQuery["getAllApplicationType"];
}
const initialState: BookState = {
  authorsList: [],
  tagsList: [],
  categoriesList: [],
  applicationTypeList: [],
};

type BookAction = {
  type: "SET_REFERENCES_BOOK";
  payload: GetReferenceCreateBookQuery;
};

const bookReducer = (state: BookState, action: BookAction): BookState => {
  switch (action.type) {
    case "SET_REFERENCES_BOOK":
      return {
        ...state,
        authorsList: action.payload.getAllAuthor || [],
        tagsList: action.payload.getAllTag || [],
        categoriesList: action.payload.getAllCategory || [],
        applicationTypeList: action.payload.getAllApplicationType || [],
      };
    default:
      return state;
  }
};

interface BookContextProps {
  state: BookState;
  dispatch: React.Dispatch<BookAction>;
}
export const BookContext = createContext<BookContextProps>({
  state: initialState,
  dispatch: () => null,
});

interface BookProviderProps {
  children: React.ReactNode;
}
export const BookProvider: React.FC<BookProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(bookReducer, initialState);

  return (
    <BookContext.Provider value={{ state, dispatch }}>
      {children}
    </BookContext.Provider>
  );
};

export const useBook = () => {
  const context = useContext(BookContext);
  if (context === undefined) {
    throw new Error("useBook must be used within BookProvider");
  }

  const { state, dispatch } = context;

  return {
    authorsList: state.authorsList,
    tagsList: state.tagsList,
    categoriesList: state.categoriesList,
    applicationTypeList: state.applicationTypeList,
    setReferences: (payload: GetReferenceCreateBookQuery) =>
      dispatch({ type: "SET_REFERENCES_BOOK", payload }),
  };
};
